<template>
  <div ref="">
    <el-dialog
      title="批量分配"
      :visible.sync="dialogFormVisible"
      @close='handleClose'
      width="800px"
      height="600px"
    >
      <el-form :model="form" class="form-list">
        <!-- <el-checkbox-group :max="multipleSelection.length" v-model="list"> -->
        <el-checkbox
          v-for="item in checkedList"
          v-model="item.checked"
          :key="item.id"
          :label="item.staff_name"
          @change="handleChange(item)"
          >{{ item.staff_name }}</el-checkbox
        >
        <!-- </el-checkbox-group> -->
        <!-- <el-radio
          v-model="radio"
          :label="item.id"
          v-for="item in checkedList"
          :key="item.id"
          @change="handleChange(item)"
          >{{ item.staff_name }}</el-radio
        > -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel" size="mini">取 消</el-button>
        <el-button type="primary" @click="handleConfirm" size="mini"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCustomerList,
  getClueList,
} from "@/api/chronicDisease/patientManage";
export default {
  name: "CDMSelectionOfTheService",
  data() {
    return {
      radio: "",
      // fullscreenLoading: false,
      multipleSelection: [],
      list: [],
      checked: false,
      //员工ids
      staff_ids: [],
      dialogFormVisible: false,
      checkedList: [],
      form: {},
      formLabelWidth: "65px",
      con_ids: this.agent_id,
    };
  },

  methods: {
    handleConfirm() {
      console.log(this.staff_ids, "111");
      // this.checkedList = this.checkedList.map(item =>{
      //   console.log(item)
      // })
      // this.staff_ids = Array.from(new Set(this.staff_ids));
      let arr = this.staff_ids.map((item) => {
        return item.id;
      });
      // console.log(arr,'arr');
      getClueList({
        staff_ids: arr,
        con_ids: this.multipleSelection,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "分配成功",
            type: "success",
          });
          this.dialogFormVisible = false;
          this.staff_ids = [];
          // console.log(this.staff_ids, "22");
          this.$emit("getList", this.tableData);
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    handleCancel(){
      this.dialogFormVisible = false
      this.staff_ids = []
    },
    handleClose(){
      // console.log(11111111111111111111111)
      this.handleCancel()
    },
    handleChange(item) {
      if (item.checked) {
        this.staff_ids.push(item);
      } else {
        this.staff_ids.forEach((val) => {
          // console.log(val,item, "val");
          if (!val.checked) {
            this.staff_ids = this.staff_ids.filter(
              (val, i) => val.id !== item.id
            );
          }
        });
      }
      // console.log(item, "item");
      // console.log(this.staff_ids, "this.staff_ids");
    },
  },
  watch: {
    dialogFormVisible(val) {
      if (val == true) {
        getCustomerList({ agent_id: this.agent_id }).then((res) => {
          if (res.code == 200) {
            this.checkedList = res.data;
            this.checkedList = this.checkedList.reduce((cur, next) => {
              //对象的find方法，查找cur中是否有next.staff_name相同的元素，没有则返回undefined
              let repeat = cur.find((item) => {
                return item.staff_name === next.staff_name;
              });
              if (!repeat) {
                cur.push({
                  ...next,
                  checked: this.checked,
                });
              }
              return cur;
            }, []);
            console.log(this.checkedList, 123);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  ::v-deep .el-checkbox {
    width: 100px;
  }
}
::v-deep .el-form {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  .el-checkbox {
    width: 150px;
    margin: 10px 0px;
  }
  .el-radio {
    width: 150px;
    margin: 10px 0px;
  }
}
</style>
