<template>
  <div>
    <el-dialog :visible.sync="dialogTableVisible" width="70%" title="分配信息">
      <el-table
        border
        size="mini"
        v-loading="listLoading"
        :header-cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        height="625"
        style="width: 100%;z-index:0"
      >
        <template v-for="(column, index) in tableHead">
          <el-table-column
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-if="column.field_type === 'textBtn' && column.visible === true"
            show-overflow-tooltip
          >
            <!--<template #header>
						<el-popover placement="bottom" title="" width="230" trigger="hover">
							<div slot="reference" class="search-header">
								<span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 5px" class="search-icon el-icon-search"></i>
							</div>
							<el-input
									size="mini"
									placeholder=""
									prefix-icon="el-icon-search"
									v-model="searchParams.buy_count"
									clearable
									@change="getList('restPage')"
								>
								</el-input>
						</el-popover>
					</template>-->
            <template slot-scope="scope">
              <a
                style="font-size: 12px; color: #2379fb"
                @click.prevent="handleCustomerDetail(scope.row)"
              >
                {{ scope.row[column.column_prop] }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="column"
            align="center"
            v-else-if="
              column.field_type === 'imageBtn' && column.visible === true
            "
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                v-if="scope.row[column.column_prop].length > 0"
                style="font-size: 12px; color: #2379fb"
                @click="
                  handleShowImage(
                    scope.row[column.column_prop],
                    scope.row[column.column_prop][0]
                  )
                "
              >
                <a>点击查看图片</a>
                <el-image
                  style="width: 0px; height: 0px"
                  :ref="scope.row[column.column_prop][0]"
                  :src="imageUrl"
                  :preview-src-list="scope.row[column.column_prop]"
                  :z-index="9999"
                >
                </el-image>
              </div>
              <div v-else>
                暂无
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :sortable="tableHeadSortable.includes(column.column_prop)"
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="column.visible === true"
            show-overflow-tooltip
          >
            <template #header>
              <el-popover
                placement="bottom"
                title=""
                min-width="160"
                trigger="click"
                v-if="
                  searchTableHead.filter(
                    (item) => item.name == column.column_prop
                  ).length > 0
                "
              >
                <span slot="reference" class="search-header">
                  <span class="search-title">{{
                    column.column_label_user_definition
                      ? column.column_label_user_definition
                      : column.column_label
                  }}</span>
                  <i
                    style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                    class="el-icon-search"
                    :class="{
                      isSearch: searchTableHead.filter(
                        (item) => item.name == column.column_prop
                      )[0].isSearch,
                      'el-icon-zoom-in': searchTableHead.filter(
                        (item) => item.name == column.column_prop
                      )[0].isSearch,
                    }"
                  />
                </span>
                <el-input
                  v-if="'order_count' == column.column_prop"
                  size="mini"
                  placeholder=""
                  prefix-icon="el-icon-search"
                  v-model="searchParams.buy_count"
                  clearable
                  @change="
                    handleSearch(column.column_prop, searchParams.buy_count)
                  "
                >
                </el-input>

                <el-select
                  v-else-if="'is_wx' == column.column_prop"
                  style="width:200px"
                  v-model="searchParams.is_wx"
                  placeholder="请选择"
                  size="mini"
                  clearable
                  @change="handleSearch(column.column_prop, searchParams.is_wx)"
                >
                  <template slot="prefix">
                    <i
                      class="el-icon-search"
                      style="width: 25px; line-height: 32px;"
                    ></i>
                  </template>
                  <el-option
                    v-for="item in optionsWX"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>

                <el-select
                  v-else-if="'visit_level' == column.column_prop"
                  style="width:200px"
                  v-model="searchParams.visit_level"
                  placeholder="请选择"
                  size="mini"
                  clearable
                  @change="
                    handleSearch(column.column_prop, searchParams.visit_level)
                  "
                >
                  <template slot="prefix">
                    <i
                      class="el-icon-search"
                      style="width: 25px; line-height: 32px;"
                    ></i>
                  </template>
                  <el-option
                    v-for="item in optionsVisitLevel"
                    :key="item.short_name"
                    :label="item.short_name"
                    :value="item.short_name"
                  >
                  </el-option>
                </el-select>

                <el-select
                  v-else-if="'from' == column.column_prop"
                  style="width:200px"
                  v-model="searchParams.from_id"
                  placeholder="请输入客户来源"
                  size="small"
                  clearable
                  @change="
                    handleSearch(column.column_prop, searchParams.from_id)
                  "
                >
                  <template slot="prefix">
                    <i
                      class="el-icon-search"
                      style="width: 25px; line-height: 32px;"
                    ></i>
                  </template>
                  <el-option
                    v-for="item in customerFromOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>

                <el-date-picker
                  v-else-if="'created_at' == column.column_prop"
                  v-model="choiceDateCreateRecord"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleCreateRecordChangeDate()"
                >
                </el-date-picker>

                <el-date-picker
                  v-else-if="'expired_at' == column.column_prop"
                  v-model="choiceDateExpire"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleExpireChangeDate"
                >
                </el-date-picker>

                <el-date-picker
                  v-else-if="'end_visit_at' == column.column_prop"
                  v-model="choiceDateEndVisit"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleEndVisitChangeDate"
                >
                </el-date-picker>

                <el-date-picker
                  v-else-if="'end_order_at' == column.column_prop"
                  v-model="choiceDateEndOrder"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleEndOrderChangeDate"
                >
                </el-date-picker>
                <el-date-picker
                  v-else-if="'next_visit_at' == column.column_prop"
                  v-model="choiceDateNextVisit"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleNextVisitChangeDate"
                >
                </el-date-picker>

                <el-select
                  v-else-if="'staff_name' == column.column_prop"
                  style="width:200px"
                  v-model="searchParams.staff_id"
                  placeholder="请选择"
                  size="mini"
                  clearable
                  @change="
                    handleSearch(column.column_prop, searchParams.staff_id)
                  "
                >
                  <template slot="prefix">
                    <i
                      class="el-icon-search"
                      style="width: 25px; line-height: 32px;"
                    ></i>
                  </template>
                  <el-option
                    v-for="item in staffOptions"
                    :key="item.id"
                    :label="item.staff_name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-popover>
              <span v-else>{{
                column.column_label_user_definition
                  ? column.column_label_user_definition
                  : column.column_label
              }}</span>
            </template>
            <template slot-scope="scope">
              <span v-if="column.column_prop === 'buy_status'">{{
                scope.row[column.column_prop] === 1 ? "未购" : "已购"
              }}</span>

              <span v-else-if="column.column_prop === 'is_wx'">
                {{ scope.row[column.column_prop] == "0" ? "否" : "是" }}
              </span>
              <span v-else-if="column.column_prop === 'supervise_status'">
                {{ scope.row[column.column_prop] | formatSuperviseStatus }}
              </span>
              <span v-else>{{ scope.row[column.column_prop] }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>

      <!----------------分页---------------->
      <!--<pagination :total="total" :page.sync="listQuery.page" -->
      <!--						:limit.sync="listQuery.limit"-->
      <!--						@pagination="getList"/>-->

      <pagination
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />

      <!--编辑表头-->
      <!-- <assignThreadRecordTableHead
        :isActiveAssignThreadRecordTableHead.sync="
          isActiveAssignThreadRecordTableHead
        "
        @_getAdminFieldIndex="_getAdminFieldIndex"
        v-if="isActiveAssignThreadRecordTableHead"
      >
      </assignThreadRecordTableHead> -->
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/index";
// import assignThreadRecordTableHead from "@/views/work/tableHead/assignThreadRecordTableHead";
import { mapGetters,mapState } from "vuex";
import { getAdminFieldIndex, assignLog } from "@/api/ggkq";

export default {
  name: "assignThreadRecord",
  components: {
    Pagination,
    // assignThreadRecordTableHead,
  },
  computed: {
    ...mapGetters(["agentId", "adminId"]),
    ...mapState({
      table_options: state => state.user.table_options,
    })
  },
  async created() {
    await this._getAdminFieldIndex();
    // await this._getStaffList()
    console.log(this.tableHead, this.tableData, 2222222222222222);
  },
  //监视
  watch: {
    dialogTableVisible: function(nVal, oVal) {
      console.log(nVal, oVal);
      if (nVal == true) {
        this.getList();
      }
    },
  },
  data() {
    return {
      searchTableHead: [
        {
          name: "order_count",
          isSearch: false,
        },
      ],
      tableHeadSortable: ["created_at"],
      isActiveAssignThreadRecordTableHead: false,
      customerFromOptions: [],
      tableHead: [],
      staffOptions: [],
      searchParams: {},
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      listLoading: false,
      tableData: [],
      id: null,
      dialogTableVisible: false,
    };
  },
  methods: {
    handleSearch(prop, value) {
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        type: "buy",
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveAssignThreadRecordTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "assignThreadRecord",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead =this.table_options.assignThreadRecord;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },

    getList(type) {
      console.log(this, "id3333333333333333333333333333333333333");
      if (type == "restPage") {
        this.listQuery.page = 1;
      }
      console.log("getList触发");

      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.limit = this.listQuery.limit;
      assignLog(this.id, this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = _data.per_page;
            this.tableData = _data.data;
            console.log(this.tableData, "数据");
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    // async _getStaffList () {
    //   var params = {
    //     agent_id: this.agentId
    //   }
    //   var result = await getStaffList(params)
    //   console.log(result)
    //   this.staffOptions = result.data
    // },
  },
};
</script>

<style scoped lang="scss">
.isSearch {
  color: #f56c6c;
}
.search-icon {
  color: #f56c6c;
}
</style>
