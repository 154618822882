<template>
  <div>
    <input
      ref="excel-upload-input"
      class="excel-upload-input"
      type="file"
      accept=".xlsx, .xls"
      @change="handleClick($event)"
    />
    <el-button
      size="mini"
      type="success"
      style="padding: 6px 7px 7px 8px;background-color:rgb(35,98,251); border-color: rgb(35,98,251)"
      @click="handleDialogFormVisible"
    >
      <i class="el-icon-plus" style="font-size: 14px" />
      <!--<a style="margin-left: 5px; font-size: 12px; " href="file/PatientRecords.xlsx">下载模板</a>-->
      <span style="margin-left: 5px; font-size: 12px; ">导入线索</span>
    </el-button>
    <el-dialog title="导入线索" :visible.sync="dialogFormVisible" >
      <el-form :model="form" class="form" v-if="flag" v-loading="loading">
        <el-form-item label="导入文件" :label-width="formLabelWidth">
          <el-link
            type="primary"
            :underline="false"
            @click="handleSelectTheFile"
            >{{ fileName }}</el-link
          >
        </el-form-item>
        <el-form-item label="文件名称" :label-width="formLabelWidth">
          <el-input
            placeholder="文件名称"
            v-model="fileName"
            :disabled="true"
            style="width: 222.44px;"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="线索类型" :label-width="formLabelWidth">
          <el-radio v-model="thread_type" label="1" border>电商线索</el-radio>
          <el-radio v-model="thread_type" label="2" border>表单线索</el-radio>
        </el-form-item>
        <el-form-item v-if="thread_type==1" label="创建订单" :label-width="formLabelWidth">
          <el-radio v-model="is_order" :label="0" border>否</el-radio>
          <el-radio v-model="is_order" :label="1" border>是</el-radio>
        </el-form-item>
        <el-form-item label="咨询产品" :label-width="formLabelWidth">
            <el-select
              v-model="spec_id"
              filterable
              placeholder="请输入内容"
            >
              <el-option
                v-for="item in productConsulting"
                :key="item.spec_id"
                :label="item.goods_name"
                :value="item.spec_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        <div class="form-box">
          <el-form-item label="档案类型" :label-width="formLabelWidth">
            <el-select
              v-model="m_type"
              filterable
              placeholder="请输入内容"
              @change="handleType(m_type)"
            >
              <el-option
                v-for="item in FileType"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="档案类别" :label-width="formLabelWidth">
            <el-select
              v-model="m_category"
              filterable
              placeholder="请输入内容"
              @change="handleCategory(m_category)"
            >
              <el-option
                v-for="item in recordsSeries"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

      
	
					<el-form-item label="移动电话" :label-width="formLabelWidth">
						<el-select
							v-model="mobileIndex"
							filterable
							placeholder="请输入内容"
						>
							<el-option
								v-for="item in importOptions"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="顾客姓名" :label-width="formLabelWidth">
						<el-select
							v-model="nameIndex"
							filterable
							placeholder="请输入内容"
						>
							<el-option
								v-for="item in importOptions"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="顾客性别" :label-width="formLabelWidth">
						<el-select
							v-model="genderIndex"
							filterable
							placeholder="请输入内容"
						>
							<el-option
								v-for="item in importOptions"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="通讯地址" :label-width="formLabelWidth">
						<el-select
							v-model="addressIndex"
							filterable
							placeholder="请输入内容"
						
						>
							<el-option
								v-for="item in importOptions"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="顾客来源" :label-width="formLabelWidth">
						<el-select
							v-model="sourceIndex"
							filterable
							placeholder="请输入内容"
							@change="handleCategory(m_category)"
						>
							<el-option
								v-for="item in importOptions"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="说明备注" :label-width="formLabelWidth">
						<el-select
							v-model="remarkIndex"
							filterable
							placeholder="请输入内容"
							@change="handleCategory(m_category)"
						>
							<el-option
								v-for="item in importOptions"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="登记时间" :label-width="formLabelWidth">
						<el-select
							v-model="orderAtIndex"
							filterable
							placeholder="请输入内容"
							@change="handleCategory(m_category)"
						>
							<el-option
								v-for="item in importOptions"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
          <el-form-item label="快递单号" :label-width="formLabelWidth">
						<el-select
							v-model="expressNoIndex"
							filterable
							placeholder="请选择快递单号"
							@change="handleCategory(m_category)"
						>
							<el-option
								v-for="item in importOptions"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
          <el-form-item label="快递公司" :label-width="formLabelWidth">
						<el-select
							v-model="companyIndex"
							filterable
							placeholder="请选择快递公司"
							@change="handleCategory(m_category)"
						>
							<el-option
								v-for="item in importOptions"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
         
        </div>
     
        <div style="font-size: 12px;margin-left: 20px;color: #666666;">备注:移动电话、顾客姓名、通信地址、顾客来源、说明备注、登记时间选项请按照表格实际表头字段与之对应,请勿随意更改。</div>
      </el-form>
			
			
			
      <div v-else>
        <div class="top-data">
          <div style="width:40%">
            <span
              style="padding:2.5px 2px;background-color:#5677fc;border-radius:10px;margin-right:8px"
            ></span
            >导入明细
          </div>
          <div class="rg_data">
            <span
              >总数:
              <span style="color:black;font-size:18px;">{{
                tableData.length
              }}</span></span
            >
            <span
              >当前:
              <span style="color:black;font-size:18px;">{{
                tableData.length
              }}</span></span
            >
            <span
              >成功:
              <span style="color:greenyellow;font-size:18px;">{{
                successCount
              }}</span></span
            >
            <span
              >失败:
              <span style="color:red;font-size:18px;">{{
                failCount
              }}</span></span
            >
          </div>
        </div>
        <el-table :data="tableData" style="width:100%" height="300">
          <el-table-column prop="order_at" label="下单时间"> </el-table-column>
          <el-table-column prop="from" label="来源"> </el-table-column>
          <el-table-column prop="remark" label="备注说明" width="180">
          </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="mobile" label="收件人电话"> </el-table-column>
          <el-table-column prop="addr" label="地址" width="180">
          </el-table-column>
          <el-table-column prop="msg" label="验证信息"> </el-table-column>
        </el-table>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel" size="mini">取 消</el-button>
        <!--<el-button-->
        <!--  type="primary"-->
        <!--  @click="handleNextStep"-->
        <!--  size="mini"-->
        <!--  v-if="next"-->
        <!--  >下一步</el-button-->
        <!--&gt;-->
				<el-button type="primary" @click="handleConfirmImport" size="mini" v-if="next"
				>确 定</el-button
				>
        <el-button type="primary" @click="handleConfirm" size="mini" v-else
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getImportThread,getImportContractMThread, postCustomImportThread } from "@/api/chronicDisease/patientManage";
import XLSX from "xlsx";
import {
  mapState
} from "vuex";
export default {
  name: "CDMImportTheClues",
  //下一步
  data() {
    return {
      goods_id:'',
      spec_id:'',
      loading:false,
      thread_type:"1",
      next: "true",
      flag: true,
      btn: "下一步",
      fileName: "选择文件",
      formLabelWidth: "80px",
      dialogFormVisible: false,
      //表格数据
      tableData: [],
      formData: new FormData(),
      //档案类型
      FileType: [
        { id: 1, label: "热线" },
        { id: 2, label: "新线" },
        { id: 3, label: "资源" },
      ],
      m_type: "",
      agent_id: this.agent_id,
      //档案类别
      recordsSeries: [
        { id: 1, label: "采线" },
        { id: 2, label: "运营" },
        { id: 3, label: "回访" },
      ],
      m_category: "",
      form: {},
      m_file: "",
      // file_name: "",
      successCount: "", //成功次数
      failCount: "", //失败次数
      importOptions:[
        {id:-1,label:'*'}
      ],//导入表格选项
			mobileIndex: -1,
			nameIndex: -1,
			genderIndex: -1,
			addressIndex: -1,
			sourceIndex: -1,
			remarkIndex: -1,
			orderAtIndex: -1,
      expressNoIndex:-1,
      companyIndex:-1,
      is_order:0
    };
  },
  computed:{
    ...mapState({
      productConsulting:state => state.options.consult_goods
    })
  },
  mounted(){
    this.$store.dispatch('options/getConsuleGoods');
  },
  updated() {
    this.getList();
  },
  methods: {
    getList(rest){
      console.log(rest,111111)
    },
    //选择文件
    handleSelectTheFile() {
      this.$refs["excel-upload-input"].click();
    },
    handleType(type) {
      // this.formData.append("m_type", type);
    },
    handleCategory(category) {
      // this.m_category = category;
      // this.formData.append("m_category", category);
    },
    handleDialogFormVisible() {
      this.handleConfirm()
      this.dialogFormVisible = true;
    },
    handleClick(e) {
      this.loading = true
      this.mobileIndex = -1
      this.nameIndex = -1
      this.genderIndex = -1
      this.addressIndex = -1
      this.sourceIndex = -1
      this.remarkIndex = -1
      this.orderAtIndex = -1
      this.expressNoIndex = -1
      this.companyIndex = -1
      this.is_order = 0
      let file = e.target.files[0];
      this.formData = new FormData();
      this.fileName = file.name;
      this.formData.append("file", file);

      var that = this
      var excelFile;
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function(e) {
        var data = e.target.result;
        excelFile = XLSX.read(data, {
          type: "binary"
        });
        var headers_key = new Array();
        var headers = new Array();
        var str = XLSX.utils.sheet_to_json(
          excelFile.Sheets[excelFile.SheetNames[0]]
        );
        //str后面的数字代表表头是从第几行开始的，自己根据情况修改
				console.log(str[0])
        
        for (var key in str[0]) {
          // console.log(key)
          headers_key.push(key); //获取表头key
         
        }
        for(let i = 0;i<headers_key.length;i++){
          that.importOptions.push({
            id:i,
            label:headers_key[i]
          })
        }
        for (var i = 0; i < headers_key.length; i++) {
          console.log(headers_key[i])
					key = headers_key[i]
          if (key.indexOf("时间") != -1) {
            that.orderAtIndex = i
            continue;
          }
          if (key.indexOf("来源") != -1) {
            that.sourceIndex = i
            continue;
          }
          if (key.indexOf("明细") != -1 || key.indexOf("备注") != -1) {
            that.remarkIndex = i
            continue;
          }
          if (key == "收件人" || key.indexOf("姓名") != -1) {
            that.nameIndex = i
            continue;
          }
          if (key.indexOf("电话") != -1 || key.indexOf("手机") != -1) {
            that.mobileIndex = i
            continue;
          }
          if (key.indexOf("地址") != -1 || key.indexOf("地") != -1) {
            that.addressIndex = i
            continue;
          }
          if (key.indexOf("性别") != -1) {
            that.genderIndex = i
            continue;
          }
          if (key.indexOf("物流单号") != -1 || key.indexOf("单号") != -1) {
            that.expressNoIndex = i
            continue;
          }
          if (key.indexOf("物流公司") != -1 || key.indexOf("公司") != -1) {
            that.companyIndex = i
            continue;
          }

        }
        that.loading = false 
        // for (var i = 0; i < headers_key.length; i++) {
        //   console.log(str[0][headers_key[i]])
        //   headers.push(str[0][headers_key[i]]); //获取表头
        // }
      }
      
    },
    //点击取消
    handleCancel() {
      this.handleConfirm()
    },
    //点击下一步
    handleNextStep() {
      console.log(this.m_type);
      console.log(this.m_category);
      console.log(this);
      if (this.fileName == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
        return;
      }
      if (this.valueType == "") {
        this.$message({
          message: "请选择档案类型",
          type: "warning",
        });
        return;
      }
      if (this.valueSeries == "") {
        this.$message({
          message: "请选择档案类别",
          type: "warning",
        });
        return;
      }
      this.m_file = this.formData;
      if(this.thread_type == 1){
        this.handleImportOrder()
      }else{
        this.handlleImportCon()
      }
    },
    //导入订单线索
    handleImportOrder(){
      getImportThread(this.m_file).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.data;
          this.failCount = res.data.failCount;
          this.successCount = res.data.successCount;
          this.next = false;
          this.flag = false;
          //清除数据
          // this.formData = new FormData();
          //刷新数据
          this.$emit("getList",this.tableData)
        }else{
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    //导入档案线索
    // handlleImportCon(){
    //   getImportContractMThread(this.m_file).then((res) => {
    //     if (res.code == 200) {
    //       this.tableData = res.data.data;
    //       this.failCount = res.data.failCount;
    //       this.successCount = res.data.successCount;
    //       this.next = false;
    //       this.flag = false;
    //       //清除数据
    //       // this.formData = new FormData();
    //       //刷新数据
    //       this.$emit("getList",this.tableData)
    //     }else{
    //       this.$message({
    //         message: res.msg,
    //         type: "warning",
    //       });
    //     }
    //   });
    // },
    handleConfirm() {
      this.flag = true;
      this.next = true,
      this.m_type = '',
      this.m_category = '',
      // this.file_name = ''
      this.fileName = '选择文件',
      this.$refs["excel-upload-input"].value = ''
      this.formData = new FormData()
      this.dialogFormVisible = false;
      this.importOptions = [
        {id:-1,label:'*'}
      ]
      this.mobileIndex = -1
      this.nameIndex = -1
      this.genderIndex = -1
      this.addressIndex = -1
      this.sourceIndex = -1
      this.remarkIndex = -1
      this.orderAtIndex = -1
      this.expressNoIndex = -1
      this.companyIndex = -1
      this.is_order = 0
    },
    async handleConfirmImport() {
      if (this.fileName == "" || this.fileName == '选择文件') {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
        return;
      }
      if (this.m_type == "") {
        this.$message({
          message: "请选择档案类型",
          type: "warning",
        });
        return;
      }
      if (this.m_category == "") {
        this.$message({
          message: "请选择档案类别",
          type: "warning",
        });
        return;
      }
      this.formData.append('m_type',this.m_type)
      this.formData.append('m_category',this.m_category)
      this.formData.append('type',this.thread_type)
      this.formData.append('mobile',this.mobileIndex)
      this.formData.append('name',this.nameIndex)
      this.formData.append('gender',this.genderIndex)
      this.formData.append('address',this.addressIndex)
      this.formData.append('source',this.sourceIndex)
      this.formData.append('remark',this.remarkIndex)
      this.formData.append('order_at',this.orderAtIndex)
      this.formData.append('express_no',this.expressNoIndex)
      this.formData.append('company',this.companyIndex)
      this.formData.append('is_order',this.is_order)
      this.formData.append('spec_id',this.spec_id)
      this.loading = true
    	var res = await postCustomImportThread(this.formData)
      this.loading = false
      if (res.code == 200) {
          this.tableData = res.data.data;
          this.failCount = res.data.failCount;
          this.successCount = res.data.successCount;
          this.next = false;
          this.flag = false;
          //清除数据
          // this.formData = new FormData();
          //刷新数据
          this.$emit("getList",this.tableData)
        }else{
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
		}
  
  },
};
</script>

<style lang="scss" scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.form-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .el-form-item {
    margin-bottom: 25px;
  }
}
::v-deep .el-dialog__header {
  background-color: #f5f2f2;
}
::v-deep .el-dialog {
  border-radius: 15px;
  overflow: hidden;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
.top-data {
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .rg_data {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    font-size: 20px;
  }
}
</style>
